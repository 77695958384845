<template>
  <div>
    <div class="app-header">
      <img alt="logo" :src="logoPath"/>
    </div>
    <div class="center">
      <b-container class="msg" v-if="btnVariant=='success'">
        <p>
          {{ $t('validationRegistration.messages.success')[0] }}
        </p>
        <p>
          {{ $t('validationRegistration.messages.success')[1] }}
        </p>
        <!-- <b-button type="button" variant="outline-primary" @click.prevent="$router.push({ name: 'SignIn' })">
          Ouvrir la page de connexion
        </b-button> -->
      </b-container>
      <b-container class="msg" v-else-if="btnVariant=='danger'">
        <p>
          {{ $t('validationRegistration.messages.error') }}
        </p>
      </b-container>
      <b-container class="msg" v-else>
        <p>
          {{ $t('validationRegistration.confirmation') }} <b-button :pressed="btnPressed" :variant="btnVariant" @click.prevent="submit">{{ $t('validationRegistration.clickHere') }}</b-button>
        </p>
      </b-container>
    </div>
    <small class="footer">
      <p>{{ $t('footer') }} <a href="https://www.neogeo.fr/" target="_blank" rel="noopener">Neogeo-Technologies</a></p>
    </small>
  </div>
</template>

<script>

import {
  mapGetters,
  mapState,
  mapActions,
  mapMutations
} from 'vuex';

const validationRegistrationStoreName = 'validation-registration';

const validationRegistrationGetters = {
  status: 'getStatus',
};

const validationRegistrationState = ['accountStatus'];
const validationRegistrationMutations = ['SET_TOKEN'];
const validationRegistrationActions = ['POST_TOKEN'];

export default {
  name: 'ValidationRegistration',
  data: () => {
    return {
      btnPressed: false,
    };
  },
  computed: {
    ...mapState(validationRegistrationStoreName, validationRegistrationState),
    ...mapGetters(validationRegistrationStoreName, validationRegistrationGetters),
    btnVariant() {
      if (this.status === true) {
        return 'success';
      } else if (this.status === false) {
        return 'danger';
      } else {
        return '';
      }
    },
    logoPath() {
      return require(process.env.VUE_APP_LOGO);
    }
  },
  created() {
    this.$store.commit('validation-registration/SET_TOKEN', this.$route.query.token);
  },
  methods: {
    ...mapActions(validationRegistrationStoreName, validationRegistrationActions),
    ...mapMutations(validationRegistrationStoreName, validationRegistrationMutations),
    async submit() {
      this.btnPressed = true;
      await this.$store.dispatch('validation-registration/POST_TOKEN');
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  position: initial;
}

.center .msg.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.center .msg.container,
.center .msg.container button {
  font-size: large;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: small;
}

.footer a {
  text-decoration: none;
}
</style>
